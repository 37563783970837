import { AbilityBox } from 'features/Horse/components'
import Stats from 'features/components/Stats'
import { Horse, HorseAbility } from 'models'
import { sortAbilities } from 'utils/helper'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseCareerWinRates from '../HorseCareerWinRates'
import HorseBodyInfoStyled from './styled'
import { getCharacteristic, getColorHorse, getImageBloodline, getRunType } from 'utils/getLinkImage'
import { useState } from 'react'
import { useToggle } from 'hooks'
import { STRING_BLOODLINE_CHARACTER } from 'apps/constants'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
interface HorseBodyInfoProps {
  horse: Horse
  isLending?: boolean
  customClass?: string
}
function HorseBodyInfo({ horse, isLending, customClass }: HorseBodyInfoProps) {
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <HorseBodyInfoStyled colorHorse={getColorHorse(horse?.bloodline?.color)}>
      {horse && (
        <div className={customClass}>
          <div className={`right-body ${isLending ? 'lending-container' : ''}`}>
            <div className='right'>
              <div className='box__bloodline'>
                {/* bloodline */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.BLOODLINE, horse?.bloodline?.name)}
                  className='image_bloodline'>
                  <MemoizedLoadImageCommon srcImg={getImageBloodline(horse?.bloodline?.name)} />
                </div>

                {/* run type */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, horse?.run_type)}
                  className='image_run_type'>
                  <MemoizedLoadImageCommon srcImg={getRunType(horse.run_type)} />
                </div>

                {/* characteristic */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, horse?.characteristic_display)}
                  className='image_charitic'>
                  <MemoizedLoadImageCommon srcImg={getCharacteristic(horse.characteristic_display)} />
                </div>

                {/* color */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.COLOR, horse?.bloodline?.color)}
                  className='image_color'></div>
              </div>
              <div className='career-win-rates'>
                <HorseCareerWinRates horse={horse} isLending={isLending} />
              </div>
              <div className='stats-body-container'>
                <Stats horse={horse} isLending={isLending} customClass='lending-stats' />
              </div>
              <div className='ability-container row gy-4'>
                {horse && sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                  <div className='col-12 col-sm-6' key={index}>
                    <AbilityBox name={skill.name_en} level={skill.level} />
                  </div>
                ))}
              </div>
              {openHorseBloodlineModal && (
                <ModalHorseBloodline
                  title={bloodlineTitle}
                  content={bloodlineContent}
                  onClose={_handleClosePopup}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </HorseBodyInfoStyled>
  )
}

export default HorseBodyInfo
