/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'hooks'
import horseApi from 'apis/horseApi'

import { links } from 'apps'
import { STRING_ABILITY, STRING_ARMOR_SKIN, STRING_BLOODLINE_CHARACTER, STRING_GENDER, TWO_STATS_TYPE } from 'apps/constants'
import { NEXT_RACE_BOTTOM_FRAME, ICON_DOPING, UNION, SHADOW_DOPING } from 'assets/images'
import { EnergyBar, RateStarItems } from 'features/Horse/components'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { HorseAbility, HorseAvailable, ListHorseStats, ListBoostItemsEquipped } from 'models'
import { useTranslation } from 'react-i18next'
import { ClassTag } from 'shared'
import { capitalizeOnlyFirstLetter, formatStatsRank, getEnergyPercent, sortAbilities, handleAsyncRequest } from 'utils/helper'
import SelectItemModal from 'features/Horse/components/SelectItemModal'
import { toast } from 'react-toastify'
import { TIME_CLOSE_MODAL } from 'apps/constants'
import openNotification from 'utils/toast'
import RemoveItemModal from 'features/Horse/components/RemoveItemModal'
import VipIcon from 'features/components/VipIcon'
import MemoizedImageFEMALEICON from 'shared/MemoizedImage/Female'
import MemoizedImageMALEICON from 'shared/MemoizedImage/Male'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import ChooseHorseItemStyled from './styled'

import {
  ListBootsType
} from 'models'
import ImageArmorSkin from 'features/components/ImageArmorSkin'
import { getAbility, getCharacteristic, getImageBloodline, getImageRegion, getRunType } from 'utils/getLinkImage'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
interface ChooseHorseItemProps {
  horse: HorseAvailable
  onHorseClick?: (horseId: number) => void
  onUseItemSuccess: () => void
  customClass?: string
}

function ChooseHorseItem({
  horse,
  onHorseClick,
  customClass = '',
  onUseItemSuccess
}: ChooseHorseItemProps) {
  const currentEnergyPercent = useMemo<number>(() => getEnergyPercent(horse.remaining_time_to_next_energy), [horse])
  const [firstTime, setFirstTime] = useState(0)
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)
  const [isSelectItemModal, toggleIsSelectItemModal] = useToggle(false)
  const [bootsTypeListDetail, setBootsTypeDetail] = useState<any>()
  const [currentStatsSelected, setCurrentStatsSelected] = useState<string>('')
  const [currentStatsSelectedTemp, setCurrentStatsSelectedTemp] = useState<string>('')
  const [horse_id] = useState(horse?.id)
  const [listItemsEquipped, setListItemsEquipped] = useState<ListBoostItemsEquipped[]>([])
  const [currentPosision, setCurrentPosision] = useState<string>('')
  const [isModalRemoveItemOpen, toggleIsRemoveItemOpen] = useToggle(false)
  const [itemDetail, setItemDetail] = useState<ListBootsType[]>([])
  const [currentIdRemove, setCurrentIdRemove] = useState<string>('')
  const [isChangeItem, toggleIsChangeItem] = useToggle(false)
  const [isLoading, setIsLoading] = useState(false)
  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false) 

  const bloodLine = useMemo<string>(() => capitalizeOnlyFirstLetter(horse.blood_line ?? horse.bloodline?.name), [horse])
  const gender = useMemo<string>(() => capitalizeOnlyFirstLetter(horse.gender), [horse])
  const lastFiveRaces = useMemo<string>(() => horse?.last_races_position?.join('-'), [horse])
  const { t } = useTranslation()
  const handleItemClick = (horseId: number) => () => {
    onHorseClick && onHorseClick(horseId)
  }  

  useEffect(() => {
    setFirstTime(horse?.remaining_time_to_next_energy)
    setListItemsEquipped(horse?.boost_items_equipped)

    if (horse) {
      horse?.weapon_items_equipped?.map((item: any) => {
        switch (item?.body_part) {
          case STRING_ARMOR_SKIN.HEAD: {
            return setHorseHead(STRING_ARMOR_SKIN.HEAD)
          }
          case STRING_ARMOR_SKIN.BODY: {
            return setHorseBody(STRING_ARMOR_SKIN.BODY)
          }
          case STRING_ARMOR_SKIN.LEG: {
            return setHorseLeg(STRING_ARMOR_SKIN.LEG)
          }
          case STRING_ARMOR_SKIN.SKIN: {
            return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
          }
          default:
            break;
        }
      })
    }
  }, [horse])

  const timer = () => setFirstTime(firstTime - 1000)
  useEffect(() => {
    const id = setInterval(timer, 1000)
    if (firstTime > 0) {
      setDisableRecovery(true)
    }
    if (firstTime <= 0) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  const showModalSelectItem = (type: string, position: string) => {
    setCurrentStatsSelected(type)
    setCurrentPosision(position)
    toggleIsSelectItemModal(true)
  }
  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const handleOnConfirmFinal = () => {
    onUseItemSuccess()
    toggleIsSelectItemModal(false)
    pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyBootsItems`), true)
  }
  const fetchUserBootsItems = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      horseId: horse_id,
      position: currentPosision  || 1,
      limit:  30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }
  function handleCurrentAmountStats(currentType: any) {
    const found = listItemsEquipped.find((element: any) => element.slot == currentType);
    if (found) {
      return (found.amount)
    } else {
      return (0)
    }
  }

  function handleListGetPosition(currentType: any) {
    const found = listItemsEquipped.filter((element) => element.slot == currentType);
    if (found) {
      return (found)
    } else {
      return ([])
    }
  }

  function handleGetIdItems(currentType: any) {
    const found = listItemsEquipped.find((element: any) => element.slot == currentType);
    if (found) {
      return (found.id)
    } else {
      return (0)
    }
  }

  function handleCurrentImgDoping(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.boost.img)
    } else {
      return (0)
    }
  }

  function handleCurrentOwnerDoping(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.item_owner.id)
    } else {
      return (0)
    }
  }  

  const onRemoveSuccess = () => {
    onUseItemSuccess()
  }

  const handleRemoveItem = async () => {
    const [error, result]: any = await handleAsyncRequest(
      horseApi.deleteHorseBoosterItem(String(horse_id), String(currentIdRemove))
    )

    if (result) {
      onRemoveSuccess()
      pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyRemovedBootsItems`), true)
    }
    if (error) {
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    toggleIsRemoveItemOpen(false)
  }
  const handleChangeItem = async () => {
    setCurrentStatsSelected(currentStatsSelectedTemp)
    toggleIsSelectItemModal(true)
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(true)
  }
  const handleCloseModalSelectItem = async () => {
    toggleIsSelectItemModal(false)
    setCurrentStatsSelected('')
    toggleIsChangeItem(false)
  }

  const handleCloseModal = () => {
    toggleIsRemoveItemOpen(false)
  }
  const showModalRemoveItem = async (id: string, position: any, type: string) => {
    const [error, result]: any = await handleAsyncRequest(horseApi.getBoostItemsDetail(id))
    if (result) {
      toggleIsRemoveItemOpen(true)
      setItemDetail(result?.data)
      setCurrentIdRemove(result?.data.id)
      setIsLoading(false)
    }
    if (error) {
      toggleIsRemoveItemOpen(false)
      setIsLoading(false)
      onUseItemSuccess()
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    setCurrentPosision(position)
    setCurrentStatsSelectedTemp(type)
  }

  const fetchListBootsItemsChange = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      tokenId: horse_id,
      position: currentPosision,
      limit:  30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListChangeBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data?.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      setBootsTypeDetail([])
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }
  useEffect(() => {
    if (isChangeItem) {
      fetchListBootsItemsChange()
    }
  }, [isChangeItem])

  useEffect(() => {
    if (currentStatsSelected !== '' && !isChangeItem) {
      fetchUserBootsItems()
    }
  }, [currentStatsSelected, currentPosision])

  const handlseShowUiTats = (arr: ListHorseStats[]) => {
    return (
      <>
        {arr
          .sort((a, b) => (TWO_STATS_TYPE.indexOf(a.stats_type) > TWO_STATS_TYPE.indexOf(b.stats_type) ? 1 : -1))
          .map(stats => (
            <div key={stats.stats_type} className='stat-item d-flex align-items-center'>
              <div className="item-left">
                <span className='stat-title color-yellow'>{stats.stats_type}</span>
                <span className='stat-content color-white'>
                  {stats.stat_rank !== null ? formatStatsRank(stats.stat_rank) : 'A'}
                </span>
              </div>
            </div>
          ))}
      </>
    )

  }
  const handleSortStatsType = () => {
    const amountUsedPlus = handleCurrentAmountStats('PLUS')
    return (
      <>
        {
          sortAbilities(horse?.list_horse_ability)?.map((item: HorseAbility, index: number) => {
            const listGetPosition = handleListGetPosition(item.name_en.toUpperCase()).sort((a: any, b: any) => a.position - b.position)
            const foundOne = listGetPosition.find((element: any) => element.position == 1);
            const foundTwo = listGetPosition.find((element: any) => element.position == 2);
            const isDopingUsedByMyPosisionOne = ((horse && horse?.user?.id) == (foundOne && foundOne?.item_owner?.id) || horse?.user === null) 
            const isDopingUsedByMyfoundTwo = ((horse && horse?.user?.id) == (foundTwo && foundTwo?.item_owner?.id) || horse?.user === null)
            const checkStar = listGetPosition.filter((element: any) => element.slot == element.boost.effect_type);

            return (
              <div className='position-relative custom-bottom-frame' key={index}>
                <div className="ability-box-container">
                  <div className='ability-box d-flex justify-content-between align-items-center'>
                    <div
                      onClick={(event) => _handleClickImageAbility(event, item.name_en)}
                      className='stat-title img__ability'
                    >
                      <MemoizedLoadImageCommon srcImg={getAbility(item.name_en)} />
                    </div>
                    <div className="group d-flex justify-content-between align-items-center">
                      <div className='level d-flex'>{
                        generateRateLevel(3, item.level , item.level + checkStar.length)
                      }</div>
                      <div className="box-plus d-flex align-items-center">
                        {foundOne
                          ? (
                            <button
                              className={`boots-doping ${( isDopingUsedByMyPosisionOne ) ? '' : 'disabled'} ${(item.level == 3) ? 'disable' : ''} `}
                              onClick={() => showModalRemoveItem(String(foundOne.id), '1', item.name_en.toUpperCase())}
                            >
                              <img src={SHADOW_DOPING} alt='' className='shadown' />
                              <img src={foundOne.boost.img} alt='' className='d-block' />
                              <span> {foundOne.amount}</span>
                            </button>
                          )
                          : (
                            <button
                              className={`boots-doping ${(item.level == 3) ? 'disable' : ''} `}
                              onClick={() => showModalSelectItem(item.name_en.toUpperCase(), '1')}
                            >
                              <img src={ICON_DOPING} alt='' className='' />
                            </button>
                          )
                        }
                        {foundTwo
                          ? (
                            <button
                              className={`boots-doping ${( isDopingUsedByMyfoundTwo) ? '' : 'disabled'} ${(item.level > 2 || item.level == 2) ? 'disable' : ''} `}
                              onClick={() => showModalRemoveItem(String(foundTwo.id), '2', item.name_en.toUpperCase())}
                            >
                              <img src={SHADOW_DOPING} alt='' className='shadown' />
                              <img src={foundTwo.boost.img} alt='' className='d-block' />
                              <span> {foundTwo.amount}</span>
                            </button>
                          )
                          : (
                            <button
                              className={`boots-doping ${(item.level > 2 || item.level == 2) ? 'disable' : ''} `}
                              onClick={() => showModalSelectItem(item.name_en.toUpperCase(), '2')}
                            >
                              <img src={ICON_DOPING} alt='' className='' />
                            </button>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <img src={NEXT_RACE_BOTTOM_FRAME} alt='' className='position-absolute bottom-frame d-none d-xl-inline-block' />
              </div>
            )
          })
        }
        <div className="item-plus d-flex justify-content-between align-items-center">
          <img src={UNION} alt='' className='frame-union' />
          <div className="name">ITEM PLUS</div>
          {amountUsedPlus == 0 ? (
            <button
              className='push-doping'
              onClick={() => showModalSelectItem('PLUS', '')}
              >
              <img src={ICON_DOPING} alt='' className='' />
            </button>

          ) : (
            <button
            className={`push-doping ${(handleCurrentOwnerDoping('PLUS') === horse?.user?.id) ? '' : 'disabled' }`}
            onClick={() => showModalRemoveItem(String(handleGetIdItems('PLUS')), '', 'PLUS')
              }
            >
              <img className='shadown' src={SHADOW_DOPING} alt="" />
              <img src={handleCurrentImgDoping('PLUS')} alt='' className='d-block' />
              {amountUsedPlus !== 0 && (
                <span> {amountUsedPlus}</span>
              )}
            </button>
          )}
        
        </div>
      </>
    )
  }

  const generateRateLevel = (maxLevel: number, currentLevel: number, afterUseDoping: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStarItems isUseDoping={i < afterUseDoping} key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  const _handleClickImageAbility = (event: React.MouseEvent, content: string) => {
    event.stopPropagation()
    switch(content?.toUpperCase()) {
      case (STRING_ABILITY.DIRT)?.toUpperCase():
      case (STRING_ABILITY.TURF)?.toUpperCase():
        setBloodlineTitle("Ability Field Type")
        break;
      case (STRING_ABILITY.LONG)?.toUpperCase():
      case (STRING_ABILITY.MEDIUM)?.toUpperCase():
      case (STRING_ABILITY.SHORT)?.toUpperCase():
        setBloodlineTitle("Ability Distance")
        break;
      default:
        break;
    }
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  return (
    <ChooseHorseItemStyled currentEnergy={currentEnergyPercent} className={customClass}>
      <div
        className='choose-horse-item d-flex justify-content-between gap-4'
        role='button'
        onClick={handleItemClick(horse.id)}
      >
        <div className="new-ui">

          <div className='mid-container flex-grow-1 d-flex'>
            <div className='left-container d-flex flex-column align-items-center'>
              {horse.active === false ? <div className='color-red font-bold'>disable</div> : ''}
              <div className='avatar-container d-flex align-items-center justify-content-center p-1'>
                <div className='avata__img'>
                  <MemoizedLoadImageCommon srcImg={horse?.sub_avatar} alt={horse?.name} />
                </div>
              </div>
              <div className='energy'>
                <EnergyBar
                  maxEnergy={horse?.max_energy || 100}
                  currentEnergy={horse?.current_energy || 0}
                  firstTime={firstTime}
                  disableRecovery={disableRecovery}
                  isShowPlusIcon
                  horse={horse}
                  onRefresh={onUseItemSuccess}
                />
              </div>
              <div className='link-container'>
                <a
                  rel='noopener noreferrer'
                  href={links.horse.detail(horse?.id)}
                  target='_blank'
                  className='link color-primary'
                >
                  {t(`${NOTIFICATION_MESSAGE}.detail`)}
                </a>
              </div>
            </div>
            <div className='horse-container'>
              <div className='d-flex align-items-center'>
                <div className='name color-white font-bold'>
                  <span className='color-yellow'>{horse.guild && `[${horse.guild?.guild_tag}]`}</span> {horse.name}
                </div>
                <div className='ct_gender ps-2'>
                  {gender === STRING_GENDER.Male ? <MemoizedImageMALEICON /> : <MemoizedImageFEMALEICON /> }
                </div>
                <div className='region'>
                  <MemoizedLoadImageCommon srcImg={getImageRegion(horse?.region)} />
                </div>
                <div className='class-type'>
                  <ClassTag text={horse.racing_class} isActive={true} />
                </div>
                {horse?.msp_details?.msp && <VipIcon top={-3} />}
              </div>

              <div className='extra-info d-flex align-items-center'>
                <span className='title color-grey'> {t(`${NOTIFICATION_MESSAGE}.career`)}</span>
                <span className='content color-white'>
                  <span className='color-yellow'> {horse.career?.total_number_of_races} </span>{' '}
                  <span>
                    {horse.career?.first_count}/{horse.career?.second_count}/{horse.career?.third_count}
                  </span>
                </span>
              </div>
              <div className='extra-info d-flex align-items-center'>
                <span className='title color-grey'>{t(`${NOTIFICATION_MESSAGE}.last5Race`)}</span>
                <span className='content color-white'>{lastFiveRaces?.length ? lastFiveRaces : '---'}</span>
              </div>

              <div className='box__bloodline'>
                {/* bloodline */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.BLOODLINE, bloodLine)}
                  className='image_bloodline'
                >
                  <MemoizedLoadImageCommon srcImg={getImageBloodline(bloodLine)} />
                </div>

                {/* run type */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, horse?.run_type)}
                  className='image_run_type'
                >
                  <MemoizedLoadImageCommon srcImg={getRunType(horse?.run_type)} />
                </div>

                {/* characteristic */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, horse?.characteristic_display)}
                  className='image_charitic'
                >
                  <MemoizedLoadImageCommon srcImg={getCharacteristic(horse?.characteristic_display)} />
                </div>
              </div>

              <div className='extra-info d-flex align-items-center'>
                <ImageArmorSkin
                  horseHead={horseHead}
                  horseBody={horseBody}
                  horseLeg={horseLeg}
                  horseSkin={horseSkin}
                  weaponItemsEquipped={horse?.weapon_items_equipped || []}
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
          <div className="list-stats">
            <div className="stats-bg">
              {handlseShowUiTats(horse?.list_horse_stats)}
            </div>
          </div>
        </div>

        <div className='right-container'>
          <div className='stat-container-border'>
            <div className='stat-container d-flex flex-wrap'>{handleSortStatsType()}</div>
          </div>
        </div>
      </div>
      {openHorseBloodlineModal && (
        <ModalHorseBloodline
          title={bloodlineTitle}
          content={bloodlineContent}
          onClose={_handleClosePopup}
        />
      )}
      {isSelectItemModal &&
        <SelectItemModal
          onConfirm={handleOnConfirmFinal}
          onCloseButtonClick={handleCloseModalSelectItem}
          listTypeBoots={bootsTypeListDetail || []}
          horse_id={String(horse?.id) || ''}
          position={currentPosision}
          currentStatsSelected={currentStatsSelected}
          isChangeItem={isChangeItem}
          oldItems={itemDetail}
          isLoading={isLoading}
        />
      }
      {isModalRemoveItemOpen && itemDetail &&
        <RemoveItemModal
          dataPreview={itemDetail}
          onRemove={handleRemoveItem}
          onChange={handleChangeItem}
          onCloseButtonClick={handleCloseModal}
          isLoading={isLoading}
        />}
    </ChooseHorseItemStyled>
  )
}

export default ChooseHorseItem